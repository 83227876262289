<template>
  <section>
    <h1>{{ $t('home.texts.welcome') }}</h1>
    <!--    <img alt="Oniros logo" :src="require('../../assets/oniros_logo.png')">-->
    <p>{{ $t('home.texts.p1') }}</p>
    <p>{{ $t('home.texts.p2') }}</p>
    <p v-html="$t('home.texts.p3')" />
    <p>{{ $t('home.texts.p4') }}</p>

    <h2>{{ $t('home.texts.list.title') }}</h2>

    <dl>
      <dt>{{ $t('home.texts.list.d1.name') }}</dt>
      <dd>{{ $t('home.texts.list.d1.title') }}</dd>
      <dt>{{ $t('home.texts.list.d2.name') }}</dt>
      <dd>{{ $t('home.texts.list.d2.title') }}</dd>
      <dt>{{ $t('home.texts.list.d3.name') }}</dt>
      <dd>{{ $t('home.texts.list.d3.title') }}</dd>
      <dt>{{ $t('home.texts.list.d4.name') }}</dt>
      <dd>{{ $t('home.texts.list.d4.title') }}</dd>
    </dl>
  </section>
</template>

<script>
export default {
  name: 'Project',
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/style';
@import '../../assets/style/backgroundPage';

section {
  padding: 15px;

  img {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  p {
    text-indent: 30px;
  }

  dl {
    padding-left: 15px;

    dd {
      font-style: italic;
      margin-bottom: 10px;
    }
  }
}
</style>
